<!-- 能耗管理--能耗报表设置 -->
<template>
  <div id="energyConsumptionReportSettings" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="page-title">
        <my-PageTitle>{{ $t('energyConsumptionReportSettings.a1') }}</my-PageTitle>
    </div>
    <div class="main">
        <div class="sider">
            <div class="add">
                <span>{{ $t('energyConsumptionReportSettings.a2') }}</span>
                <a-button type="primary" ghost icon="plus" size="small" @click="openTemplteAdd">
                    {{ $t('energyConsumptionReportSettings.a3') }}
                </a-button>
            </div>
            <div class="menu">
                <a-menu v-model="templateSelect" mode="inline">
                    <a-menu-item v-for="(item,index) in templateList" :key="index" @click="getTemplateData(item)">
                        <div class="menu-item">
                            <div>{{ item.name }}</div>
                            <div v-show="item.type == 0">
                                <a-icon type="form" :style="{ fontSize: '18px',color: '#7682CE' }" @click="openNameAmend(item)"/>
                                <a-icon type="delete" :style="{ fontSize: '18px',color: '#7682CE' }" @click="openTemplteDelete(item)" /> 
                            </div>
                        </div>
                    </a-menu-item>
                </a-menu>
            </div>
        </div>
        <div class="template-settings">
            <div class="template">
                <div class="title">
                    {{ $t('energyConsumptionReportSettings.a4') }}
                </div>
                <div class="box-container">
                    <a-checkbox v-for="(item,index) in totalTemplateElementList" :key="index" v-model="item.enable">
                        {{ item.fieldName }}
                    </a-checkbox>
                </div>
                <div class="title">
                    <span style="margin-right:15px">{{ $t('energyConsumptionReportSettings.a5') }}</span>
                    <a-switch v-model="detailEnable"/>
                </div>
                <div class="box-container">
                    <a-checkbox v-for="(item,index) in detailTemplateElementList" :key="index" v-model="item.enable">
                        {{ item.fieldName }}
                    </a-checkbox>
                </div>
                <div class="title">
                    <!-- <span style="margin-right:15px">行列置换</span>
                    <a-switch v-model="ranksEnable"/> -->
                </div>
            </div>
            <div class="settings">
                <a-button type="primary" @click="templateDataSet">
                    {{ $t('energyConsumptionReportSettings.a6') }}
                </a-button>
            </div>
        </div>
    </div>
    <!-- 添加模版 -->
    <a-modal v-model="templateAddVisible" title="添加模版" centered :width="400" @cancel="templateAddCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="templateAddCancel">
                {{ $t('energyConsumptionReportSettings.a7') }}
            </a-button>
            <a-button key="submit" type="primary" @click="templateAddOK" :disabled="disabled">
                {{ $t('energyConsumptionReportSettings.a8') }}
            </a-button>
        </template>
        <div v-loading="addLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-row type="flex" align="middle" style="margin-bottom:20px">
                    <a-col :span="5">
                        <div style="margin-bottom:24px">
                            {{ $t('energyConsumptionReportSettings.a9') }}
                        </div>
                    </a-col>
                    <a-col :span="16">
                        <a-form-model ref="ruleForm" :model="addData" :rules="rules">
                            <a-form-model-item prop="name">
                                <a-input v-model="addData.name" :maxLength="30" :placeholder="$t('energyConsumptionReportSettings.a10')" allow-clear/>
                            </a-form-model-item>
                        </a-form-model>
                    </a-col>
                </a-row>
            </div>
        </div>
    </a-modal>
    <!-- 修改模版名称 -->
    <a-modal v-model="nameAmendVisible" :title="$t('energyConsumptionReportSettings.a11')" centered :width="400" @cancel="nameAmendCancel" :destroyOnClose="true" v-drag>
        <template slot="footer">
            <a-button key="back" @click="nameAmendCancel">
                {{ $t('energyConsumptionReportSettings.a7') }}
            </a-button>
            <a-button key="submit" type="primary" @click="nameAmendOK" :disabled="disabled">
                {{ $t('energyConsumptionReportSettings.a8') }}
            </a-button>
        </template>
        <div v-loading="nameAmendLoading" :element-loading-text="$t('alarm.a0')">
            <div class="padding">
                <a-row type="flex" align="middle" style="margin-bottom:20px">
                    <a-col :span="5">
                        <div style="margin-bottom:24px">
                            {{ $t('energyConsumptionReportSettings.a9') }}
                        </div>
                    </a-col>
                    <a-col :span="16">
                        <a-form-model ref="amendForm" :model="amendData" :rules="rules">
                            <a-form-model-item prop="name">
                                <a-input v-model="amendData.name" :maxLength="30" :placeholder="$t('energyConsumptionReportSettings.a10')" allow-clear/>
                            </a-form-model-item>
                        </a-form-model>
                    </a-col>
                </a-row>
            </div>
        </div>
    </a-modal>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { getTemplateList,getTemplateAdd,getTemplateAmend,getTemplateDelete,getTemplateDatas,getTemplateDataSet } from '../../api/energy'

export default {
    components:{
        'my-PageTitle':PageTitle
    },
    data(){
        return{
            loading:false,
            templateList:[],
            templateSelect:[],
            templateData:{},
            templateAddVisible:false,
            disabled:false,
            addLoading:false,
            addData:{
                name:''
            },
            rules: {
                name: [
                    { required: true, message: this.$t('energyConsumptionReportSettings.a10'), trigger: 'change' },
                ],
            },
            nameAmendVisible:false,
            nameAmendLoading:false,
            amendData:{
                name:''
            },
            amendDataCopy:{
                name:''
            },
            totalTemplateElementList:[],
            detailTemplateElementList:[],
            detailEnable:false,
            ranksEnable:false,
        }
    },
    // 页面初始化数据获取
    mounted(){
        this.loading = true
        const data = {
            siteId:this.$route.query.id
        }
        getTemplateList(data)
        .then(res=>{
            // console.log(res)
            const { data } = res
            this.templateList = data
            this.templateSelect = [0]
            this.templateData = data[0]
            this.getTemplateData(data[0])
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods: {
        // 打开模版添加弹框
        openTemplteAdd(){
            this.templateAddVisible = true
        },
        // 关闭模版弹框
        templateAddCancel(){
            this.templateAddVisible = false
            this.addData.name = ''
        },
        // 添加模版数据API
        templateAddOK(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.addLoading = true
                    this.disabled = true
                    const query = { siteId:this.$route.query.id }
                    const data = { name: this.addData.name }
                    getTemplateAdd(query,data)
                    .then(res=>{
                        // console.log(res)
                        if(res.errorCode == '00'){
                            const { data } = res
                            this.templateList.push(data)
                            this.$message.success(res.msg)
                            this.templateAddCancel()
                        }else{
                            this.$message.error(res.msg)
                        }
                        this.addLoading = false
                        this.disabled = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.addLoading = false
                        this.disabled = false
                    })
                } else {
                    return false;
                }
            });
        },
        // 打开名称修改弹框
        openNameAmend(item){
            this.amendDataCopy = item
            const _data = JSON.stringify(item)
            const data = JSON.parse(_data)
            this.amendData = data
            this.nameAmendVisible = true
        },
        // 关闭名称修改弹框
        nameAmendCancel(){
            this.nameAmendVisible = false
        },
        // 模版名称修改API
        nameAmendOK(){
            this.$refs.amendForm.validate(valid => {
                if (valid) {
                    this.nameAmendLoading = true
                    this.disabled = true
                    const data = this.amendData
                    console.log(data)
                    getTemplateAmend(data)
                    .then(res=>{
                        // console.log(res)
                        if(res.errorCode == '00'){
                            const { data } = res
                            this.amendDataCopy.name = data.name
                            this.$message.success(res.msg)
                            this.nameAmendCancel()
                        }else{
                            this.$message.error(res.msg)
                        }
                        this.nameAmendLoading = false
                        this.disabled = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.nameAmendLoading = false
                        this.disabled = false
                    })
                } else {
                    return false;
                }
            });
        },
        // 删除模版
        openTemplteDelete(value){
             this.$confirm({
                title: `${this.$t('energyConsumptionReportSettings.a12')}${value.name}?`,
                centered: true,
                onOk:()=> {
                    return new Promise((resolve, reject) => {
                        const data = { id:value.id }
                        getTemplateDelete(data)
                        .then(res=>{
                            // console.log(res)
                            if(res.errorCode == '00'){
                                this.$message.success(res.msg)
                                const arr = this.templateList.filter(item=>{
                                    if(item.id !== value.id){
                                        return item
                                    }
                                })
                                this.templateList = arr
                            }else{
                                this.$message.error(res.msg)
                            }
                            resolve()
                        })
                        .catch(err=>{
                            console.log(err)
                            reject()
                        })
                    }).catch(() => { return false})
                },
            })
        },
        // 获取模版数据
        getTemplateData(item){
            this.templateData = item
            this.loading = true
            const data = { id:item.id}
            getTemplateDatas(data)
            .then(res=>{
                console.log(res)
                const { data:{detailEnable,detailTemplateElementList,totalTemplateElementList,ranksEnable}} = res
                this.detailEnable = detailEnable
                this.detailTemplateElementList = detailTemplateElementList
                this.totalTemplateElementList = totalTemplateElementList
                this.ranksEnable = ranksEnable
                this.loading = false
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 模版数据设置
        templateDataSet(){
            this.loading = true
            const query = { id:this.templateData.id}
            const data = {
                detailEnable:this.detailEnable,
                detailTemplateElementList:this.detailTemplateElementList,
                ranksEnable:this.ranksEnable,
                totalTemplateElementList:this.totalTemplateElementList
            }
            console.log(query,data)
            getTemplateDataSet(query,data)
            .then(res=>{
                console.log(res)
                if(res.errorCode == '00'){
                    this.$message.success(res.msg)
                }else{
                    this.$message.error(res.msg)
                }
                this.loading = false
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
    },
}
</script>

<style scoped>
div /deep/.ant-modal-body{
    padding: 0px;
}
div /deep/.ant-modal-header{
    background-color: #7682CE;
}
#energyConsumptionReportSettings{
    width: 100%;
    height: 100%;
}
.main{
    display: flex;
    height: calc(100% - 50px);
    padding: 10px 20px 0px 20px;
}
.add{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    height: 30px;
}
.menu{
    height: calc(100% - 30px);
    overflow: auto;
}
.menu-item{
    display: flex;
    justify-content: space-between;
}
.sider{
    width: 300px;
    margin-right: 15px;
}
.template-settings{
    width: calc(100% - 315px);
}
.template{
    height: calc(100% - 52px);
}
.box-container{
    border: 1px solid rgba(12, 12, 12, 0.25);
    border-radius: 3px;
    padding: 10px;
}
.title{
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
}
.settings{
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
}
.padding{
    height: 120px;
    padding: 30px 20px 0px 20px;
}
</style>